import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./help.css";

const HelpDropdown: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Dropdown align="end">
        <Dropdown.Toggle variant="link" id="help-dropdown" className="p-0 border-0">
            <div
            style={{
                backgroundColor: 'white',
                borderRadius: '60%',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '3.5px',
            }}
            >
            <FaQuestionCircle size={18} style={{ color: '#03234a' }} />
            </div>
        </Dropdown.Toggle>
      <Dropdown.Menu className="help-dropdown-menu">
        <Dropdown.Item 
        onClick={() => navigate('/onboard')}
        className="helpItem"
        >
          Tutorial Page
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HelpDropdown;