

import React, { useEffect} from 'react';
import { Table, Container, Form, Button, Row, Col, Modal, Pagination, DropdownButton, Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';

import "./index.scss";
import TableSkeleton from './TableSkeleton';
import WarningModal from './WarningModal';
import PreviewModal from './PreviewModal';
import { useCatalogueData } from './useCatalogHook';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import editIcon from "../../components/Icons/pencil-square.svg";
import SelectedDataSet from './SelectDataSet';
import ErrorModal from './DataSetSelectedMessageModal';
import { FaDownload, FaTrash } from 'react-icons/fa';
import DeleteConfirmationModal from './DeleteViewPromptModal';
import BackArrowIcon from '../../assets/images/svg/ArrowLeftCircle';

const LoaderPopup = () => {
    return (
      <div className="loader-overlay">
        <div className="loader-container">
          <div className="spinner"></div>
          <div className="loader-text">Loading...</div>
        </div>
      </div>
    );
  };

const CustomTable = () => {

    const {
        data,
        columnsToShow,
        showModal,
        showViewModal,
        warningModalShow,
        previewModal,
        formattedString,
        selectedItems,
        selectedItemsForFilters,
        modalFormData,
        loading,
        formFields,
        viewName,
        viewData,
        selectedcatalog,
        columns,
        columnsForFilter,
        formData,
        activeViewUpdate,
        showStatusModal,
        // showDtaSetModal,
        multipleSelection,
        selectedEndpoints,
        showErrorModal,
        itemsPerPage,
        currentPage,
        apiFailedStatus,
        deleteViewActionTriggered,
        showDeleteModal,
        viewNameToDelete,
        setActiveViewUpdate,
        setShowModal,
        setShowViewModal,
        setWarningModalShow,
        setPreviewModal,
        setSelectedcatalog,
        setSelectedItems,
        handleSelect,
        handleSelectForFilter,
        handleChange,
        handleSubmit,
        handleEditClick,
        handleInputChange,
        handleViewInputChange,
        handleSave,
        handleViewSave,
        handleCreateNewView,
        warningMessage,
        setLoading,
        handleApplyFilter,
        handleHide,
        handleUpdateSelectedView,
        // handleOpenDtaSetModal,
        // handleCloseDtaSetModal,
        setData,
        setMultipleSelection,
        setSelectedEndpoints,
        setShowErrorModal,
        handlePageChange,
        handleDeleteView,
        handleDeleteClick,
        handleCloseDeleteModal,
        downloadCSV,
        isLoadingDownloadCSV
    } = useCatalogueData();


    const alwaysSelectedItems = ['source_entity_column_name','logical_name', 'source_entity_name', 'data_source_name', 'data_type', ];
    useEffect(() => {
        setSelectedItems(alwaysSelectedItems);
    }, []);

    const renderPaginationItems = () => {
        const totalPages = Math.ceil(data.total / itemsPerPage);
        const items = [];
        const maxVisiblePages = 5; 
      
        let startPage = Math.max(2, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages - 1, currentPage + Math.floor(maxVisiblePages / 2));
      
        if (currentPage <= maxVisiblePages - Math.floor(maxVisiblePages / 2)) {
          startPage = 2;
          endPage = Math.min(totalPages - 1, maxVisiblePages);
        } else if (currentPage >= totalPages - Math.floor(maxVisiblePages / 2)) {
          startPage = Math.max(2, totalPages - maxVisiblePages + 1);
          endPage = totalPages - 1;
        }
      
        items.push(
          <Pagination.Item
            key={1}
            active={1 === currentPage}
            onClick={() => handlePageChange(1)}
            className={`custom-pagination-item ${1 === currentPage ? 'active' : ''}`}
          >
            1
          </Pagination.Item>
        );
      
        if (startPage > 2) {
          items.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }
      
        for (let number = startPage; number <= endPage; number++) {
          items.push(
            <Pagination.Item
              key={number}
              active={number === currentPage}
              onClick={() => handlePageChange(number)}
              className={`custom-pagination-item ${number === currentPage ? 'active' : ''}`}
            >
              {number}
            </Pagination.Item>
          );
        }
      
        if (endPage < totalPages - 1) {
          items.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }
      
        if (totalPages > 1) {
          items.push(
            <Pagination.Item
              key={totalPages}
              active={totalPages === currentPage}
              onClick={() => handlePageChange(totalPages)}
              className={`custom-pagination-item ${totalPages === currentPage ? 'active' : ''}`}
            >
              {totalPages}
            </Pagination.Item>
          );
        }
      
        return items;
      };
    
    const handleSelectView = (view:any) => {
        setSelectedcatalog({
            id: view.configuration_id,
            name: view.view_name,
        });
        setActiveViewUpdate(true);
        setLoading(true);
    };

    const iconStyle = {
        marginRight: '8px',
        fontSize: '18px',
    };

    return (
        <>
            <div className="topSectionContainer">
                <Container style={{marginLeft:0}}>
                    <Row>
                        {selectedcatalog.name && 
                            <div style={{cursor:"pointer", width:"80px", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:5}}
                                onClick={()=>
                                    window.location.reload()
                                }
                            > 
                                <BackArrowIcon/>
                                Back                           
                            </div>
                        }
                        <Col style={{display:"flex", flexDirection:"row", gap:20}}>
                            <h1>Data Catalog</h1>
                            {isLoadingDownloadCSV && <LoaderPopup />}
                            <Button
                                className="buttonDownload"
                                onClick={downloadCSV}
                            >
                                <FaDownload style={iconStyle} />
                                Download CSV
                            </Button>
                        </Col>
                        { selectedcatalog &&
                            <Col style={{ display:'flex', alignItems:"center"}}>
                                <h3 style={{textTransform:"capitalize"}}>{selectedcatalog.name}</h3>
                            </Col>
                        }
                    </Row>
                </Container>
                
                <div className="topSectionContainerViewButtonContainer">
                    <div className="view-dropdown-container">
                        <DropdownButton
                            variant='none'
                            title={selectedcatalog.name || 'Select View'}
                            className="custom-dropdown"
                            drop="down-centered"
                        >
                            <div className="dropdown-list-container">
                                {viewData && viewData.length !== 0 ? (
                                    <div className="dropdown-scrollable-list">
                                    {viewData.map((view:any) => (
                                        <OverlayTrigger
                                        key={view.configuration_id}
                                        placement="left"
                                        overlay={<Tooltip>{view.view_name}</Tooltip>} 
                                        >
                                        <Dropdown.Item
                                            eventKey={view.configuration_id.toString()}
                                            className="dropdown-item"
                                            onClick={() => handleSelectView(view)}
                                            style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '300px',
                                            }}
                                        >
                                            <span>{view.view_name}</span>
                                            <FaTrash
                                            style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                                marginLeft: '10px',
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleDeleteClick({
                                                viewName: view.view_name,
                                                viewId: view.configuration_id,
                                                });
                                            }}
                                            />
                                        </Dropdown.Item>
                                        </OverlayTrigger>
                                    ))}
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
                                    <p>No Views available to display</p>
                                    </div>
                                )}
                                </div>
                        </DropdownButton>

                        <DeleteConfirmationModal
                            show={showDeleteModal}
                            handleClose={handleCloseDeleteModal}
                            handleDelete={handleDeleteView}
                            viewName={viewNameToDelete.viewName}
                        />
                    </div>
                    <Button type="submit" className="topSectionContainerViewButton" onClick={!activeViewUpdate ? handleCreateNewView : handleUpdateSelectedView}>
                        {activeViewUpdate ? "Update Selected View": "Create New View"}
                    </Button>
                </div>
                
            </div>
            <br></br>
            <Container fluid className="middleSectionStyle">
                <div>
                    <SelectedDataSet setData={setData} selectedItems={selectedItems} formattedString={formattedString}
                        multipleSelection={multipleSelection} setMultipleSelection={setMultipleSelection} selectedEndpoints={selectedEndpoints} setSelectedEndpoints={setSelectedEndpoints}
                        setShowErrorModal = {setShowErrorModal} currentPage={currentPage}
                    />
                </div>
                <div className="selectFieldsContainer">
                    <Table bordered hover>
                        <thead className="selectFieldsTableHeader">
                            <tr>
                                <th className="selectFieldsTableHeaderContentStyle">Select Fields</th>
                            </tr>
                        </thead>
                        <tbody>
                            {columns?.map((item: any, index: number) => {
                                const isNonInteractable = item.field === 'source_entity_column_name' || item.field === 'source_entity_name' || item.field === 'data_source_name';
                                return (
                                    <tr 
                                        key={index}
                                        style={{  
                                            color: "black", 
                                            backgroundColor: isNonInteractable ? '#e0e0e0' : 'transparent' 
                                        }}
                                        onClick={() => !isNonInteractable && handleSelect(item)}
                                    >
                                        <td className="selectFieldsContentHeader">
                                            <Form.Check
                                                type="checkbox"
                                                checked={selectedItems.includes(item.field)}
                                                onChange={() => handleSelect(item)}
                                                onClick={(e) => e.stopPropagation()}
                                                disabled={isNonInteractable} 
                                            />
                                            {item.header}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                    <div className="selectFieldsContainer">
                    <Table bordered hover>
                    <thead className="selectFieldsTableHeader">
                        <tr>
                        <th className="selectFieldsTableHeaderContentStyle">Select Filters</th>
                        </tr>
                    </thead>
                    <tbody>
                        {columnsForFilter?.map((item) => (
                        <tr
                            key={item.field}
                            style={{
                            color: 'black',
                            }}
                            onClick={() => handleSelectForFilter(item.field)}
                        >
                            <td className="checkboxStyle">
                            <Form.Check
                                type="checkbox"
                                checked={selectedItemsForFilters.includes(item.field)}
                                onChange={() => handleSelectForFilter(item.field)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            {item.header}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </Table>
                </div>

                <div className="outer-container">
                    <div className="content-area">
                    <Table className="table bordered hover">
                        <thead className="table-header">
                        <tr>
                            <th>Filters</th>
                        </tr>
                        </thead>
                        {selectedItemsForFilters.length > 0 ? (
                        <tbody>
                            <tr>
                            <td>
                                <Form>
                                {formFields
                                    .filter((field) => selectedItemsForFilters.includes(field.name))
                                    .map((field) => (
                                    <Form.Group className="mb-3" controlId={field.controlId} key={field.name}>
                                        <Form.Label>{field.label}</Form.Label>
                                        {field.type === 'checkbox' ? (
                                        <Form.Check
                                            type={field.type}
                                            name={field.name}
                                            checked={formData[field.name]}
                                            onChange={handleChange}
                                        />
                                        ) : (
                                        <Form.Control
                                            type={field.type}
                                            name={field.name}
                                            value={formData[field.name]}
                                            onChange={handleChange}
                                        />
                                        )}
                                    </Form.Group>
                                    ))}
                                </Form>
                            </td>
                            </tr>
                        </tbody>
                        ) : (
                        <span className="noFilterMessage">No Filter is Selected</span>
                        )}
                    </Table>
                    </div>
                    <div className="filter-action-bar">
                        <Button type="submit" className="apply-filter-button" onClick={()=>{handleApplyFilter(currentPage)}} disabled={formattedString.length === 0}>
                            Apply Filter
                        </Button>
                    </div>
                </div>
            </Container>
            <br></br>
            <Container fluid className="mainContentContainer">
                {loading ? (<TableSkeleton columns={selectedItems.length} />)
                    :(
                        <>
                        {!apiFailedStatus ? 
                        <div className="full-size-scroll-hide">
                            {data?.data?.length > 0 ? <Table striped bordered className="tableStyleMainContent" id="table3">
                                <thead className="tableHeader">
                                    <tr style={{ background: "#03234a" }}>
                                        {columnsToShow?.map((column: any, index: number) => {
                                            if (
                                                column.header === "Source Entity Column Details Id" ||
                                                column.header === "Source Entity Column Id" ||
                                                column.header === "Source Entity Column Detail Id"
                                            ) {
                                                return null;
                                            } else {
                                                return (
                                                    <th key={index} style={{ minWidth: "200px" }} className="table-head-cell">
                                                        <div  className="centeredHeaderName">
                                                            {column.header}
                                                        </div>
                                                    </th>
                                                );
                                            }
                                        })}
                                        
                                        { data?.data?.length !== 0 &&
                                        <th
                                            style={{
                                                minWidth: "80px",
                                                position: "sticky",
                                                right: 0,
                                                background: "#03234a",
                                                zIndex: 3,
    
                                            }}
                                            className="table-head-cell"
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    padding: "1rem",
                                                    textAlign: "center",
                                                    background:"#4c5e75"
                                                }}
                                            >
                                                Edit
                                            </div>
                                        </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data?.map((row: any, rowIndex: any) => {
                                        return(
                                        <tr
                                            key={rowIndex}
                                            style={{ backgroundColor: rowIndex % 2 === 0 ? "#ceddf0" : "transparent", color: "black" }}
                                        >
                                            {columnsToShow?.map((column: any, colIndex: number) => {
                                                
                                                if (
                                                    column.header === "Source Entity Column Details Id" ||
                                                    column.header === "Source Entity Column Id" ||
                                                    column.header === "Source Entity Column Detail Id"
                                                ) {
                                                    return null;
                                                } else {
                                                    return (
                                                        <td
                                                            key={`${rowIndex}-${colIndex}`}
                                                            style={{
                                                                backgroundColor: rowIndex % 2 === 0 ? "#ceddf0" : "white",
                                                                color: "black",
                                                                minWidth: "150px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <div style={{ padding: "1rem", textAlign: "left" }}>
                                                                {typeof row[column.field] === "boolean" ? (
                                                                    row[column.field] ? "True" : "False"
                                                                ) : (
                                                                    row[column.field]
                                                                )}
                                                            </div>
                                                        </td>
                                                    );
                                                }
                                            })}
                                            <td
                                                style={{
                                                    backgroundColor: rowIndex % 2 === 0 ? "#ceddf0" : "transparent",
                                                    color: "black",
                                                    minWidth: "10px",
                                                    textAlign: "center",
                                                    position: "sticky",
                                                    right: 0,
                                                    background: rowIndex % 2 ===  0 ? "#ceddf0" : "white" ,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <img
                                                style={{ marginTop: "1.3rem", cursor:"pointer", width:"20px" }}
                                                onClick={() => handleEditClick(row)}
                                                src={editIcon}
                                                alt="Edit"
                                                />
                                            </td>
                                        </tr>
                                        )
                                    }) 
                                        }
                                </tbody>
                            </Table> : <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"25vh"}}>
                                            <p>No data Exists to Show for Current catalog Configurations</p>
                                        </div>}
                            
                        </div>
                        : 
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"25vh"}}>
                            <p>Something Went Wrong, Couldn't Fetch Catalog Data</p>
                        </div>
                        }
                        </>
                    )
                }
                <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Row</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                            {columnsToShow?.map((column:any, index:any) => {
                                if (column.header === "Source Entity Column Detail Id" || column.header === "Source Entity Column Id" || column.header === "Source Entity Column Details Id"){
                                    return null
                                }else{
                                    return(
                                    <Form.Group key={index} controlId={`form${column.field}`}>
                                        <Form.Label>{column.header}</Form.Label>
                                        
                                        {column.header === "Enable Genai" || column.header === "Pii" ?

                                        <Form.Check
                                        type="checkbox"
                                        name={column.field}
                                        checked={modalFormData[column.field]}
                                        onChange={handleInputChange}
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={ 
                                            column.header === "Data Source" || 
                                            column.header === "Source Entity Column" || 
                                            column.header === "Source Entity" || 
                                            column.header === "Data Type"}
                                        />
                                        : 
                                        <Form.Control
                                            type="text"
                                            name={column.field}
                                            value={modalFormData ? modalFormData[column.field] : ''}
                                            onChange={handleInputChange}
                                            disabled={
                                                column.header === "Data Source" || 
                                                column.header === "Source Entity Column" || 
                                                column.header === "Source Entity" || 
                                                column.header === "Data Type"
                                            && true}
                                        /> }
                                    </Form.Group>
                            )}})}
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button style={{background:"#293d57"}} onClick={handleSave}>Save changes</Button>
                </Modal.Footer>
                </Modal>
            </Container>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                <Pagination>{renderPaginationItems()}</Pagination>  
            </div>
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Set View Name</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                                <Form.Group controlId="formSingleInput">
                                <Form.Label>Enter View Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="singleInput"
                                    value={viewName}
                                    onChange={handleViewInputChange}
                                />
                                </Form.Group>
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setShowViewModal(false)
                        }}>Close</Button>
                        <Button style={{background:"#293d57"}} onClick={handleViewSave}>Save changes</Button>
                    </Modal.Footer>
                </Modal>
            <WarningModal
                show={warningModalShow}
                openPreview={setPreviewModal}
                onCross={setWarningModalShow}
                message={warningMessage}
                primaryColor="#03234a"
                secondaryColor="#adb5bd"
            />
            <PreviewModal
                show={previewModal}
                onCross={() => {
                    setPreviewModal(false)
                }}
                onContinue = {handleSubmit}
                selectedItems={selectedItems}
                formattedString={formattedString}
            />
            <ModalPopup
            show={showStatusModal}
            onHide={handleHide}
            bodyHead="Info Message"
            bodyTitle={activeViewUpdate || deleteViewActionTriggered? "View Deleted Successfully" : activeViewUpdate ? "View updated successfully" : "New view created Successfully"}
            />
            <ErrorModal
                showModal={showErrorModal}
                setShowErrorModal = {setShowErrorModal}
            />

        </>
    );
};


export default CustomTable;