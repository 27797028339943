import React, { useState, useEffect } from "react";
import WadeLogo from "../../../components/Icons/WadeLogo.svg";
import styles from "./login.module.scss";
import LoginLeftIcon from "../../Icons/LoginLeftIcon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLoginAccessToken } from "../../../services/login.service";
import { getRefreshToken } from "../../../services/refreshToken.service";
import { getIsLoggedInCheck } from "../../../services/isLoggedInCheck.service";
import { readUsers } from "../../../services/readUsers.service";
import { Form, InputGroup } from "react-bootstrap";
import Group9 from "../../../assets/images/Group 9.png";
import PasswordInput from "../../PasswordInput/PasswordInput";
import AuthenticatorDialog from "../../../containers/Authenticator/Authenticator/AuthenticatorDialog";
import CodeVerificationDialog from "../../../containers/Authenticator/CodeVerifiaction/CodeVerificationDialog";
import { setIsAuthorized, setPassword } from "../../../redux/login/actions";
import CustomLoader from "../../Loader/Spinner";

interface OTPInputProps {
  isLoggedInHandler: () => void;
}

const Login: React.FC<OTPInputProps> = ({
  isLoggedInHandler,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [invalidmailId, setInvalidMailId] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [emailOrPasswordEmpty, setEmailOrPasswordEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [incorrectEmailInputChange, setIncorrectEmailInputChange] =
    useState(false);
  const [incorrectPasswordInputChange, setIncorrectPasswordInputChange] =
    useState(false);
  const [userId, setUserId] = useState(null);
  const [authenticatorDialog, setAuthenticatorDialog] = useState(false);
  const [codeVerificationDialog, setCodeVerificationDialog] = useState(false);
  const [authMethod, setAuthMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.removeItem("isLoggedIn");
  }, []);

  const onDoneButtonClick = () => {
    readUsers(login.email, login.password).then((response) => {
      if (response.is_verified === false) {
        navigate("/");
      } else {
        dispatch(setIsAuthorized(true));
        // navigate("/onboard");
        localStorage.setItem("isLoggedIn", "true");
        isLoggedInHandler();
        navigate("/home");

      }
    });
  };

  const handleSuccessSubmit = () => {
    setCodeVerificationDialog(false);
    setIsLoading(false);
    onDoneButtonClick();
  };

  const onChange = (e: any) => {
    if (e.target.name === "email" && (invalidmailId || emailOrPasswordEmpty)) {
      setIncorrectEmailInputChange(true);
    } else if (
      e.target.name === "password" &&
      (invalidPassword || passwordEmpty)
    ) {
      setIncorrectPasswordInputChange(true);
    }
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const onForgetPasswordClick = () => {
    navigate("./forgot_password");
  };

  const validateEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(login.email)) {
      return true;
    } else {
      return false;
    }
  };

    /**
   * handleTokenErrors:
   *   - Checks if the error detail has known token-related messages
   *   - Attempts to fix them by calling getRefreshToken or getLoginAccessToken
   *   - Returns the new token result if successful, or throws if unrecoverable
   */
  async function handleTokenErrors(error: any, email: string, password: string) {
    const detail = error?.resBody?.detail || "";

    if (detail.includes("Access token expired. Try refreshing token.")) {
      console.log("Access token expired. Refreshing...");
      const refreshResponse = await getRefreshToken(
        localStorage.getItem("refresh_token"),
        email
      );
      localStorage.setItem("access_token", refreshResponse.access_token);
      localStorage.setItem("refresh_token", refreshResponse.refresh_token);

      const loginTokenResponse = await getLoginAccessToken(email, password);
      localStorage.setItem("access_token", loginTokenResponse.access_token);
      localStorage.setItem("refresh_token", loginTokenResponse.refresh_token);
      localStorage.setItem("user_id", loginTokenResponse.user_id);

      return loginTokenResponse;
    }

    if (detail.includes("Refresh token has been revoked")) {
      const loginTokenResponse = await getLoginAccessToken(email, password);
      localStorage.setItem("access_token", loginTokenResponse.access_token);
      localStorage.setItem("refresh_token", loginTokenResponse.refresh_token);
      localStorage.setItem("user_id", loginTokenResponse.user_id);

      return loginTokenResponse;
    }

    if (detail.includes("No active token for user with email")) {
      const refreshResponse = await getRefreshToken(
        localStorage.getItem("refresh_token"),
        email
      );
      localStorage.setItem("access_token", refreshResponse.access_token);

      const loginTokenResponse = await getLoginAccessToken(email, password);
      localStorage.setItem("access_token", loginTokenResponse.access_token);
      localStorage.setItem("refresh_token", loginTokenResponse.refresh_token);
      localStorage.setItem("user_id", loginTokenResponse.user_id);

      return loginTokenResponse;
    }

    throw error;
  }

  async function safeReadUsers(email: string, password: string) {
    try {
      return await readUsers(email, password);
    } catch (err) {
      const newTokens = await handleTokenErrors(err, email, password);
      if (newTokens) {
        return await readUsers(email, password);
      }
      throw err;
    }
  }

  const loginClickHandler = async () => {
    setIncorrectEmailInputChange(false);
    setIncorrectPasswordInputChange(false);
    setInvalidMailId(false);
    setInvalidPassword(false);
    setEmailOrPasswordEmpty(false);
    setPasswordEmpty(false);

    if (login.email === "" || login.password === "") {
      if (login.email !== "" && login.password === "") {
        setPasswordEmpty(true);
      }
      if (login.email === "" && login.password !== "") {
        setEmailOrPasswordEmpty(true);
      }
      if (login.email === "" && login.password === "") {
        setEmailOrPasswordEmpty(true);
        setPasswordEmpty(true);
      }
      return;
    }

    if (!validateEmail()) {
      setInvalidMailId(true);
      setInvalidPassword(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await safeReadUsers(login.email, login.password);

      if (response.logged_in_using_temporary_password) {
        navigate("/change_password", {
          state: {
            temporary_password: login.password,
            email: login.email,
          },
        });
        return;
      }

      localStorage.setItem("customer_id", response.customer_id);
      localStorage.setItem("email", login.email);
      dispatch(setPassword(login.password));

      if (response.email === login.email) {
        setAuthMethod(response.auth_method);

        const isLoggedInCheckResponse = await getIsLoggedInCheck(
          response.user_id,
          login.email,
          login.password
        );

        setUserId(isLoggedInCheckResponse.user_id);
        if (isLoggedInCheckResponse.has_logged_in_using_totp) {
          try {
            const tokenRes = await getLoginAccessToken(login.email, login.password);
            localStorage.setItem("refresh_token", tokenRes.refresh_token);
            localStorage.setItem("access_token", tokenRes.access_token);
            localStorage.setItem("user_id", tokenRes.user_id);
            setCodeVerificationDialog(true);
          } catch (tokenErr) {
            console.error("Failed to get token:", tokenErr);
            try {
              await handleTokenErrors(tokenErr, login.email, login.password);
            } catch (finalErr) {
              const tokenRes = await getLoginAccessToken(login.email, login.password);
              localStorage.setItem("refresh_token", tokenRes.refresh_token);
              localStorage.setItem("access_token", tokenRes.access_token);
              localStorage.setItem("user_id", tokenRes.user_id);
              setInvalidPassword(true);
              setCodeVerificationDialog(false);
            }
          }
          setCodeVerificationDialog(true);
        } else {
          setAuthenticatorDialog(true);
        }
      }
    } catch (error) {
      console.error("Failed in loginClickHandler:", error);

      setIsLoading(false);
      setInvalidMailId(true);
      setInvalidPassword(true);
      setEmailOrPasswordEmpty(false);
      setPasswordEmpty(false);
    }
  };

  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.loginPageLeftPanel}>
        <img
          id={styles.loginLeftIconId}
          src={LoginLeftIcon}
          alt="LoginLeftIcon"
        />
        <div className={styles.loginPageMainHeading}>
          Accelerated Data Insights. Made Simple.
        </div>
        <div className={styles.loginPageSubHeading}>
          We transform your domain experts and data engineers to a high
          performing team, focusing on understanding and deriving value out of
          your key data assets.
        </div>
      </div>

      <div className={styles.loginPageRightPanel}>
        <img id={styles.wadeLogoIconId} src={WadeLogo} alt="WADELOGO" />
        <div id={styles.loginPageLoginHeading}>Login</div>
        <div>
          <Form.Group>
            <Form.Label id={styles.loginPageLabelInput}>Email</Form.Label>
            <InputGroup>
              <Form.Control
                id={`${
                  incorrectEmailInputChange
                    ? `${styles.loginPageEmailInput}`
                    : `${
                        invalidmailId || emailOrPasswordEmpty
                          ? `${styles.loginPagePasswordWrongInput}`
                          : `${styles.loginPageEmailInput}`
                      }`
                }`}
                name="email"
                type="email"
                placeholder="Type your email"
                value={login.email}
                onChange={(e) => onChange(e)}
                disabled={isLoading}
              />
              {validateEmail() === true && login.email !== "" && (
                <InputGroup.Text
                  className={`${
                    !incorrectEmailInputChange &&
                    (emailOrPasswordEmpty || invalidmailId)
                      ? `${styles.emailError}`
                      : `${styles.emailImgContainer}`
                  }`}
                >
                  <img src={Group9} alt="tick-icon" />
                </InputGroup.Text>
              )}
            </InputGroup>
          </Form.Group>

          <PasswordInput
            id={`${
              incorrectPasswordInputChange
                ? `${styles.loginPagePasswordInput}`
                : `${
                    invalidPassword || passwordEmpty || emailOrPasswordEmpty
                      ? `${styles.loginPagePasswordWrongInput}`
                      : `${styles.loginPagePasswordInput}`
                  }`
            }`}
            labelId={styles.loginPageLabelInput}
            label="Password"
            name="password"
            type="password"
            placeholder="Type your password"
            ariaDescribedBy="Enter Password"
            value={login.password}
            maxlength={100}
            isDisabled={isLoading}
            onChange={(e) => onChange(e)}
            isError={
              !incorrectPasswordInputChange &&
              (invalidPassword ||
                passwordEmpty ||
                emailOrPasswordEmpty ||
                invalidmailId)
            }
          />
          {(invalidmailId || invalidPassword) && (
            <div
              id="invalidMessageDisplay"
              className={styles.loginInvalidmailId}
            >
              Invalid Email or Password. Please try again.
            </div>
          )}
          {(emailOrPasswordEmpty || passwordEmpty) && (
            <div
              id="invalidMessageDisplay"
              className={styles.loginInvalidmailId}
            >
              Email or Password fields cannot be empty. Please enter Email and
              password.
            </div>
          )}
          <span
            className={styles.loginPageForgetPassword}
            onClick={onForgetPasswordClick}
          >
            Forgot Password?
          </span>
        </div>
        <button
          type="button"
          id={isLoading ? styles.loginDisabled : styles.loginPageNewPasswordProceedButton}
          disabled={isLoading}
          onClick={loginClickHandler}
        >
          {isLoading ? <CustomLoader variant="blue" />  : `LOGIN`}
        </button>
        <div id={styles.loginCopyRightText}>
          <span>©2025 All Rights Reserved</span>
          <span id={styles.innerCopyRightText}>I</span>
          <a href="https://wadeinsight.com/privacy-policy/" target="_blank">
            {" "}
            Privacy Policy
          </a>
        </div>
        <AuthenticatorDialog
          show={authenticatorDialog}
          onHide={() => {
            setAuthenticatorDialog(false)
            setIsLoading(false)
          }}
          email={login.email}
          password={login.password}
          user_id={userId}
        />
        {codeVerificationDialog && (
          <CodeVerificationDialog
            show={codeVerificationDialog}
            handleSuccessSubmit={handleSuccessSubmit}
            email={login.email}
            password={login.password}
            user_id={userId}
            verifyType="existingUser"
            authMethod={authMethod}
            length={6}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
