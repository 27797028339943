import React, { useState, useEffect } from "react";
import AddNewEnvironmentPopup from "../../containers/EnvironmentDetails/AddNewEnvironmentPopup";
import WadeLogo_White from "../../components/Icons/WadeLogo_White.svg";
import { assignEnvironmentDetails } from "../../services/user.service";
import addPlusicon from "../../components/Icons/addPlusicon.svg";
import FileIcon from "../../components/Icons/EnvFileIcone.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Group543 from "../../assets/images/svg/group543";
import { Link, useLocation } from "react-router-dom";
import "./NavHeader.scss";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  Button,
  Container,
} from "react-bootstrap";
import HelpDropdown from "../Help/help";

function NavHeader(props: any) {
  const { updateEnvironmentsDetails, isLoggedIn } = props;

  const customer_id = parseInt(localStorage.getItem("customer_id"));
  const location = useLocation();

  const [showAddEnvPopup, setShowAddEnvPopup] = useState<boolean>(false);
  const [environmentOptions, setEnvironmentOptions] = useState<any>();
  const [selectedEnvironmentType, setSelectedEnvironmentType] =
  useState<string>(environmentOptions?.[0]?.customer_environment_name);


  useEffect(() => {
    const storedEnvId = localStorage.getItem("environment_id");
  
    if (isLoggedIn || customer_id) {
      assignEnvironmentDetails()?.then((res) => {
        setEnvironmentOptions(res); 

        if (!storedEnvId) {
          const firstEnv = res?.[0]?.environment_id;
          if (firstEnv) {
            localStorage.setItem("environment_id", firstEnv);
            setSelectedEnvironmentType(
              res?.filter(
                (item: any) => item?.environment_id === firstEnv
              )?.[0]?.customer_environment_name
            );
          }
        } else {
          setSelectedEnvironmentType(
            res?.filter(
              (item: any) => item?.environment_id === parseInt(storedEnvId)
            )?.[0]?.customer_environment_name
          );
        }
      });
    }
  }, [isLoggedIn, customer_id]);

  return (
    <div className="navbar-container">
      {(isLoggedIn || localStorage.getItem("isLoggedIn") === "true") && (
        <Navbar className="nav-header-outer-container">
          <Container fluid className="custom-header-fluid">
            <Navbar.Brand className="custom-brand">
              <img
                id="wade-logo-white-header"
                src={WadeLogo_White}
                alt="WadeLogo_White"
                height={"28px"}
              />
            </Navbar.Brand>
            <Form className="environment_inner_container">
              {/* <div className="navbar-environment"> */}
                <HelpDropdown/>
              {/* </div> */}
              <div className="navbar-environment">
                <NavDropdown
                  title={
                    selectedEnvironmentType === undefined
                      ? environmentOptions?.[0]?.customer_environment_name
                      : selectedEnvironmentType
                  }
                  id="basic-nav-dropdown"
                  renderMenuOnMount={true}
                >
                  <Nav.Link
                    id="basic-nav-dropdown-environment-Button"
                    onClick={() => setShowAddEnvPopup(true)}
                  >
                    <Button>
                      <img alt="addPlusicon" src={addPlusicon} />
                      <span>Add Workspace</span>
                    </Button>
                  </Nav.Link>
                  <div className="environment-dropdown-items">
                    {environmentOptions &&
                      environmentOptions?.map((environmentType: any) => {
                        return (
                          <Nav.Link
                            id="basic-nav-dropdown-link-env"
                            href="/workspaceDetails"
                            as={Link}
                            to="/workspaceDetails"
                            onClick={() => {
                              setSelectedEnvironmentType(
                                environmentType?.customer_environment_name
                              );
                              localStorage.setItem(
                                "environment_id",
                                environmentType?.environment_id
                              );
                              updateEnvironmentsDetails(
                                environmentType?.environment_id
                              );
                            }}
                          >
                            {environmentType?.customer_environment_name}
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip className="toolTipMessage">
                                  View Details
                                </Tooltip>
                              }
                            >
                              <img
                                className="InputInfoIcon"
                                width={24}
                                height={24}
                                src={FileIcon}
                                alt="FileIcon"
                              />
                            </OverlayTrigger>
                          </Nav.Link>
                        );
                      })}
                  </div>
                </NavDropdown>
              </div>
              <NavDropdown
                id="user-nav-dropdown"
                title={
                  <div className="navbar-profile">
                    <div className="navbar-email">
                      {localStorage.getItem("email")}
                    </div>
                    <Group543 />
                  </div>
                }
              >
                <Nav.Link
                  id="basic-nav-dropdown-link_profileIcon"
                  href="/support"
                  as={Link}
                  to="/support"
                  active={location.pathname === "/support"}
                >
                  Support
                </Nav.Link>
              </NavDropdown>
            </Form>
          </Container>
        </Navbar>
      )}
      {showAddEnvPopup && (
        <AddNewEnvironmentPopup
          show={showAddEnvPopup}
          onHide={() => setShowAddEnvPopup(false)}
        />
      )}
    </div>
  );
}

export default NavHeader;
